export default class HomeDropdown {
  constructor () {
    this.registerEvents()
  }
  registerEvents () {
    $('body').on('mouseenter', '.all-categories .dropdown-box > li > a', this.onCategoryTopClick.bind(this))
  }
  onCategoryTopClick (e) {
    let $this = $(e.target)
    if ($this.hasClass('has-submenu')) {
      e.preventDefault()
      let $wrapper = $this.parent()
      if (!$wrapper.hasClass('open')) {
        $('.dropdown-box > li.open').removeClass('open')
      }
      $wrapper.toggleClass('open')
    }
  }
}
