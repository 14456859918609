export default class MainMenu {
  constructor () {
    this.registerEvents()
  }

  registerEvents () {
    $('body').on('mouseenter click', '.js-root-category', this.onCategoryTopClick.bind(this))
    $('body').on('click', '.js-show-all-subcategories', this.showAllSubCategories)
  }

  onCategoryTopClick (e) {
    e.preventDefault()

    let target = $(e.target)
    let parent = $(e.target).parent()

    if (target.hasClass('has-submenu')) {
      $('.js-category-links-hidden').addClass('is-hidden')
      $('.js-show-all-subcategories').removeClass('is-hidden')

      $('.desktop-menu .categories-list li.open').removeClass('open')

      parent.toggleClass('open')
    }
  }

  showAllSubCategories () {
    $('.js-category-links-hidden').removeClass('is-hidden')
    $(this).addClass('is-hidden')
  }
}