export default class MobileNavigation {
  constructor () {
    jQuery('body').mobileNav({
      hideOnClickOutside: true,
      menuActiveClass: 'nav-active',
      menuOpener: '.nav-opener',
      menuDrop: '.nav-panel'
    })

    jQuery('.search-wrap').mobileNav({
      hideOnClickOutside: true,
      menuActiveClass: 'search-active',
      menuOpener: '.search-opener',
      menuDrop: '.search-form'
    })

    // $('body').on('click', '.mobile-menu .categories > li > a', this.onCategoryTopClick.bind(this));
    $('body').on('click', '.mobile-menu .categories > li > a.link-opener', this.onLinkOpenerClick.bind(this))

    $('.category-list > li > a').on('click', e => {
      this.categoryPageClick(e)
    })

    jQuery('.js-subscribe-email-wrap').mobileNav({
      hideOnClickOutside: true,
      menuActiveClass: 'subscribe-email-active',
      menuOpener: '.subscribe-email-opener',
      menuDrop: '.subscribe-email-form'
    })

    $('body').on('click', '.mobile-nav', this.openMobileNav.bind(this))
    $('body').on('click', '.sub-nav-toggle', this.openCategoriesMenu.bind(this))
    $('body').on('click', '.sub-nav-categories-list  > li > a', this.openCategoriesMenu.bind(this))
    $('body').on('click', '.sub-nav-toggle-filter', this.openFilterMenu.bind(this))
    $('body').on('click', '.dropdown_newsletter', this.openNewsletter)
  }

  categoryPageClick (event) {
    let element = $(event.target)
    let parent = element.parent()
    parent.siblings().removeClass('active')
    parent.addClass('active')
  }

  onCategoryTopClick (e) {
    let $this = $(e.target)
    if ($this.hasClass('has-submenu')) {
      e.preventDefault()
      let $wrapper = $this.parent()
      if (!$wrapper.hasClass('open')) {
        $('.mobile-menu .categories li.open').removeClass('open')
      }
      $wrapper.toggleClass('open')
    }
  }

  openMobileNav () {
    $('.mobile-nav').toggleClass('active')
  }

  openCategoriesMenu () {
    $('.sub-nav-categories-list').toggleClass('active')
  }
  openFilterMenu () {
    $('.sub-nav-product-list').toggleClass('active')
  }

  onLinkOpenerClick (event) {
    let element = $(event.target)
    element.toggleClass('active')
  }

  openNewsletter () {
    $('.dropdown').toggleClass('active')
  }
}
